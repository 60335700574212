// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDTypography from "../MDTypography";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function KVDate({ date, color, fontWeight, includeTime }) {
  const fromDate = new Date(date);
  const year = fromDate.getFullYear(); // 2019
  const day = fromDate.getDate();
  const monthName = months[fromDate.getMonth()];
  const dayName = days[fromDate.getDay()];
  const hours = fromDate.getHours();
  const minutes = `0${fromDate.getMinutes()}`.slice(-2);
  const seconds = `0${fromDate.getSeconds()}`.slice(-2);
  let dateFormatted = `${dayName}, ${day} ${monthName} ${year}`;
  if (includeTime) {
    dateFormatted += ` ${hours}:${minutes}:${seconds}`;
  }
  return (
    <MDTypography variant="caption" color={color} fontWeight={fontWeight}>
      {`${dateFormatted}`}
    </MDTypography>
  );
}

// Setting default values for the props of MDButton
KVDate.defaultProps = {
  date: new Date().toISOString(),
  color: "text",
  fontWeight: "regular",
  includeTime: false,
};

// Typechecking props for the MDButton
KVDate.propTypes = {
  date: PropTypes.string,
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  includeTime: PropTypes.bool,
};

export default KVDate;
