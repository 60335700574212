/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { useQuery } from "react-query";
import { useEffect, useMemo, useState } from "react";

import getEvents from "hooks/api/KVGet";
import KVDate from "components/KVDate";
import { useAuth0 } from "@auth0/auth0-react";

export default function eventsData({ take, skip, sortBy, orderBy, searchBy, searchValue }) {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [token, setToken] = useState("");

  let rows = [
    {
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Loading ...
        </MDTypography>
      ),
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </MDTypography>
      ),
    },
  ];
  let total = 1;
  useEffect(async () => {
    const accessToken = await getAccessTokenSilently();
    setToken(accessToken);
  }, [getAccessTokenSilently]);
  const { isLoading, isError, data } = useQuery(
    [
      `bathroomActivity`,
      {
        token,
        take,
        skip,
        sortBy,
        orderBy,
        searchBy,
        searchValue,
        endpoint: "bathroom-activity",
        logout,
      },
    ],
    getEvents,
    { enabled: isAuthenticated && token !== "" }
  );
  useMemo(() => {
    const getStoolFOBText = (row) => {
      if (row.stoolFOBFound === null) {
        return "n/a";
      }

      return row.stoolFOBFound ? "Yes" : "No";
    };

    if (!isLoading && !isError && data && data.bathroomActivities.length) {
      rows = data.bathroomActivities.map((row) => ({
        bathroomActivityUuid: row.bathroomActivityUuid,
        deviceSerial: row.deviceSerial,
        startTimestamp: row.eventTimestamps.startedOn ? (
          <KVDate date={`${row.eventTimestamps.startedOn}`} includeTime />
        ) : (
          "n/a"
        ),
        endTimestamp: row.eventTimestamps.endedOn ? (
          <KVDate date={`${row.eventTimestamps.endedOn}`} includeTime />
        ) : (
          "n/a"
        ),
        siteId: row.siteId,
        subjectKey: row.subjectKey,
        stoolFOBFound: getStoolFOBText(row),
        urineColorValue: row.urineColorValue === null ? "n/a" : row.urineColorValue,
      }));
      total = data.total;
    }
  }, [rows]);
  return {
    columns: [
      {
        Header: "Event ID",
        accessor: "bathroomActivityUuid",
        sortBy: "bathroomActivityUuid",
        align: "left",
      },
      { Header: "Device Serial", accessor: "deviceSerial", sortBy: "deviceSerial", align: "left" },
      {
        Header: "Started On",
        accessor: "startTimestamp",
        sortBy: "startedOn",
        align: "center",
      },
      { Header: "Ended On", accessor: "endTimestamp", sortBy: "endedOn", align: "center" },
      { Header: "Site ID", accessor: "siteId", sortBy: "siteId", align: "center" },
      { Header: "Subject Key", accessor: "subjectKey", sortBy: "subjectKey", align: "center" },
      {
        Header: "Stool FOB Found",
        accessor: "stoolFOBFound",
        sortBy: "stoolFOBFound",
        align: "center",
      },
      {
        Header: "Urine Color",
        accessor: "urineColorValue",
        sortBy: "urineColorValue",
        align: "center",
      },
    ],
    rows,
    total,
  };
}
