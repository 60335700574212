import { useAuth0 } from "@auth0/auth0-react";
import MDButton from "../MDButton";

function KVAuth0LogOut() {
  const { logout, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <MDButton
      variant="gradient"
      color="info"
      fullWidth
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log Out
    </MDButton>
  );
}

export default KVAuth0LogOut;
