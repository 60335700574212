// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetchWithFilter } from "hooks/api/KVGet";

function Dashboard() {
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();

  const [stats, setStats] = useState({
    totalCapturedImages: "Loading...",
    totalEventsLast24h: "Loading...",
    totalSubjects: "Loading...",
  });

  useEffect(async () => {
    const accessToken = await getAccessTokenSilently();
    const data = await fetchWithFilter({ endpoint: "dashboard-stats", token: accessToken, logout });
    setStats(data);
  }, [getIdTokenClaims, getAccessTokenSilently]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="camera_enhance"
                title="Total Number of images captured for the company (all time)"
                count={stats.totalCapturedImages}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="settings_remote"
                title="Total number of events in last 24 hours"
                count={stats.totalEventsLast24h}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="people_alt"
                title="Total Number of Subjects"
                count={stats.totalSubjects}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
