export const fetchWithFilter = async ({
  token,
  take,
  skip,
  sortBy,
  orderBy,
  searchBy,
  searchValue,
  endpoint,
  fullEndpoint,
  logout,
}) => {
  const url = new URL(process.env.REACT_APP_API_BASE_URI);
  url.pathname = `/ct/admin/${endpoint}`;
  if (fullEndpoint) {
    url.pathname = fullEndpoint;
  }

  // paginate
  if (take !== undefined) url.searchParams.append("take", take);
  if (skip !== undefined) url.searchParams.append("skip", skip);

  // sort
  if (sortBy && orderBy) {
    url.searchParams.append("sortBy", sortBy);
    url.searchParams.append("orderBy", orderBy);
  }

  // filter
  if (searchBy && searchValue) {
    url.searchParams.append([searchBy], searchValue);
  }

  // fetch data
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      // eslint-disable-next-line no-alert
      alert("Unauthorized, please login again!");
      if (logout) logout();
    } else {
      throw await response.json();
    }
  }

  return response.json();
};

// eslint-disable-next-line consistent-return
export const getWithFilter = async (queryFunctionContext) => {
  const { token, take, skip, sortBy, orderBy, searchBy, searchValue, endpoint, logout } =
    queryFunctionContext.queryKey[1];
  try {
    if (!token) {
      throw new Error("Bearer token not found!");
    }

    return await fetchWithFilter({
      token,
      take,
      skip,
      sortBy,
      orderBy,
      searchBy,
      searchValue,
      endpoint,
      logout,
    });
  } catch (e) {
    throw new Error(`${e.message} (while fetching data from ${endpoint})`);
  }
};

export default getWithFilter;
