/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQuery } from "react-query";
import { useEffect, useMemo, useState } from "react";
import getUsers from "hooks/api/KVGet";
import { useAuth0 } from "@auth0/auth0-react";
import KVDate from "../../../components/KVDate";

export default function subjectsData({ take, skip, sortBy, orderBy }) {
  const User = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  let rows = [
    {
      firstName: <User name="Loading ..." email="..." />,
      action: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </MDTypography>
      ),
    },
  ];
  let total = 1;

  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [token, setToken] = useState("");

  useEffect(async () => {
    const accessToken = await getAccessTokenSilently();
    setToken(accessToken);
  }, [getAccessTokenSilently]);

  const { isLoading, isError, data } = useQuery(
    ["subjects", { token, take, skip, sortBy, orderBy, endpoint: "subjects", logout }],
    getUsers,
    { enabled: isAuthenticated && token !== "" }
  );

  useMemo(() => {
    if (!isLoading && !isError && data && data.subjects.length) {
      rows = data.subjects.map((row) => ({
        siteId: row.siteId,
        subjectKey: row.subjectKey,
        bathroomActivityUUID: row.bathroomActivityUUID || "n/a",
        createdOn: row.lastActivityTimestamps?.createdOn ? (
          <KVDate date={`${row.lastActivityTimestamps?.createdOn}`} includeTime />
        ) : (
          "n/a"
        ),
        startedOn: row.lastActivityTimestamps?.startedOn ? (
          <KVDate date={`${row.lastActivityTimestamps?.startedOn}`} includeTime />
        ) : (
          "n/a"
        ),
        endedOn: row.lastActivityTimestamps?.endedOn ? (
          <KVDate date={`${row.lastActivityTimestamps?.endedOn}`} includeTime />
        ) : (
          "n/a"
        ),
      }));
      total = data.total;
    }
  }, [rows]);
  return {
    columns: [
      {
        Header: "Site ID",
        accessor: "siteId",
        align: "left",
      },
      { Header: "Subject / Sample Key", accessor: "subjectKey", align: "left" },
      { Header: "Event UUID", accessor: "bathroomActivityUUID", align: "left" },
      {
        Header: "Last Event Created On",
        accessor: "createdOn",
        align: "left",
      },
      {
        Header: "Last Event Started On",
        accessor: "startedOn",
        align: "left",
      },
      {
        Header: "Last Event Ended On",
        accessor: "endedOn",
        align: "left",
      },
    ],
    rows,
    total,
  };
}
