import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import KVAuth0LogOut from "components/KVAuth0LogOut";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

function KVAuth0LoginButton() {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <MDTypography variant="body2">Loading ...</MDTypography>;
  }
  return (
    <MDBox>
      {error && (
        <div>
          <MDTypography>
            <b>Error: </b> {error?.message}
          </MDTypography>
          <KVAuth0LogOut />
        </div>
      )}
      {!isAuthenticated && !error && (
        <MDButton variant="gradient" color="info" fullWidth onClick={() => loginWithRedirect()}>
          sign in
        </MDButton>
      )}
      {isAuthenticated && <Navigate to="/dashboard" />}
    </MDBox>
  );
}

export default KVAuth0LoginButton;
