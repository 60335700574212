// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import KVAuth0LogOut from "components/KVAuth0LogOut";
import { useAuth0 } from "@auth0/auth0-react";

function Basic() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <MDBox textAlign="center">Loading...</MDBox>;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mt={4} mb={1}>
              <MDBox mt={3} mb={1} textAlign="center">
                {isAuthenticated && <span>Signed in as {user?.email || "N/A"}</span>}
                <KVAuth0LogOut />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
