// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useState } from "react";
import eventsData from "./data/eventsTableData";

function Events() {
  const defaultValue = 50;
  const [page, setPage] = useState({ take: defaultValue, skip: 0 });
  const [pageSetting, setPageSetting] = useState({
    pageIndex: 0,
    pageSize: defaultValue,
    sortBy: "endedOn",
    orderBy: "desc",
    searchBy: "",
    searchValue: "",
  });
  const { columns, rows, total } = eventsData({
    ...page,
    sortBy: pageSetting.sortBy,
    orderBy: pageSetting.orderBy,
    searchBy: pageSetting.searchBy,
    searchValue: pageSetting.searchValue,
  });
  const pageChangeHandler = (pageDetail) => {
    const { pageIndex, pageSize } = pageDetail;
    setPageSetting({ ...pageSetting, pageIndex, pageSize });
    const skip = pageIndex === 0 ? 0 : pageIndex * pageSize;
    setPage({ take: pageSize, skip });
  };
  const pageSortHandler = (column) => {
    let orderBy = "";
    const psOrderBy = pageSetting.sortBy === column.sortBy ? pageSetting.orderBy : "";
    if (psOrderBy === "") {
      orderBy = "asc";
    } else if (psOrderBy === "asc") {
      orderBy = "desc";
    } else if (psOrderBy === "desc") {
      orderBy = "asc";
    }

    setPageSetting({ sortBy: column.sortBy, orderBy });
  };

  const callbackSearchInput = (searchInputValue) => {
    setPageSetting({
      ...pageSetting,
      pageIndex: 0,
      searchBy: "deviceSerial",
      searchValue: searchInputValue,
    });
    setPage({ take: page.take, skip: 0 });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        searchInputLabel="Search Events by Device Serial"
        callbackSearchInput={callbackSearchInput}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Events
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={{ defaultValue, entries: [20, 25, 50, 100] }}
                  totalEntries={total}
                  isSorted
                  showTotalEntries
                  noEndBorder
                  onPageChange={pageChangeHandler}
                  onPageSort={pageSortHandler}
                  pageSettings={pageSetting}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Events;
