/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { useQuery } from "react-query";
import { useMemo, useEffect, useState } from "react";
import getDevices from "hooks/api/KVGet";
import KVDate from "components/KVDate";
import { useAuth0 } from "@auth0/auth0-react";

export default function devicesData({ take, skip, sortBy, orderBy, searchBy, searchValue }) {
  let rows = [
    {
      uuid: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Loading ...
        </MDTypography>
      ),
    },
  ];
  let total = 1;
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [token, setToken] = useState("");

  useEffect(async () => {
    const accessToken = await getAccessTokenSilently();
    setToken(accessToken);
  }, [getAccessTokenSilently]);

  const { isLoading, isError, data } = useQuery(
    [
      "devices",
      { token, take, skip, sortBy, orderBy, searchBy, searchValue, endpoint: "devices", logout },
    ],
    getDevices,
    { enabled: isAuthenticated && token !== "" }
  );
  useMemo(() => {
    if (!isLoading && !isError && data && data.devices && data.devices.length) {
      rows = data.devices.map((row) => ({
        deviceName: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {row.deviceName}
          </MDTypography>
        ),
        deviceSerial: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {row.deviceSerial}
          </MDTypography>
        ),
        lastEventStartTimestamp: row.lastActivityTimestamps?.startedOn ? (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <KVDate
              fontWeight="bold"
              date={`${row.lastActivityTimestamps.startedOn}`}
              includeTime
            />
          </MDTypography>
        ) : (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            n/a
          </MDTypography>
        ),
        lastEventEndTimestamp: row.lastActivityTimestamps?.endedOn ? (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <KVDate fontWeight="bold" date={`${row.lastActivityTimestamps.endedOn}`} includeTime />
          </MDTypography>
        ) : (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            n/a
          </MDTypography>
        ),
        batteryPercentage: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {row.batteryPercentage}%{" "}
            {row.deviceStatusUpdatedOn ? (
              <>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  style={{ padding: "0 5px" }}
                >
                  at
                </MDTypography>
                <KVDate fontWeight="bold" date={`${row.deviceStatusUpdatedOn}`} includeTime />
              </>
            ) : (
              ""
            )}
          </MDTypography>
        ),
        siteId: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {row.siteId}
          </MDTypography>
        ),
      }));
      total = data.total;
    }
  }, [rows]);
  return {
    columns: [
      { Header: "Device Name", accessor: "deviceName", align: "left" },
      { Header: "Serial Number", accessor: "deviceSerial", align: "left" },
      { Header: "Last Event Start Timestamp", accessor: "lastEventStartTimestamp", align: "left" },
      { Header: "Last Event End Timestamp", accessor: "lastEventEndTimestamp", align: "left" },
      { Header: "Battery Level", accessor: "batteryPercentage", align: "left" },
      { Header: "Site ID", accessor: "siteId", align: "left" },
    ],
    rows,
    total,
  };
}
